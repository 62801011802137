import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const logoImg = (
  <StaticImage
    className="offer__commercial--logo"
    src="../../assets/images/HyperView-512x127.png"
    alt="Logo HyperView"
    placeholder="tracedSVG"
  />
);

const diagramImg = (
  <StaticImage
    className="offer__products"
    src="../../assets/images/offer-products/diagram.png"
    alt="diagram przetwarzania informacji przestrzennych"
    placeholder="tracedSVG"
  />
);

const arrowSettings = {
  size: 45,
  strokeWidth: 10,
  rotateDeg: -90,
};

const productPics = {
  0: (
    <div className="offer-product__pic-container">
      <StaticImage
        className="offer-product__pic offer-product__pic--filter"
        src="../../assets/images/offer-products/maps.png"
        alt="mapy"
        placeholder="blurred"
      />
    </div>
  ),
  1: (
    <div className="offer-product__pic-container">
      <div className="offer-product__pic--icon offer-product__pic--icon-1"></div>
    </div>
  ),
  2: (
    <div className="offer-product__pic-container offer-product__pic-container--two-pics">
      <div className="offer-product__pic--icon offer-product__pic--icon-2"></div>
      <StaticImage
        className="offer-product__pic offer-product__pic--second-img"
        src="../../assets/images/offer-products/sowa-diagram.png"
        alt="diagram aplikacji SOWA"
        placeholder="blurred"
      />
    </div>
  ),
};

export { logoImg, diagramImg, arrowSettings, productPics };
