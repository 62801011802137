import React from "react";
import { Arrow } from "utils";
import { products } from "staticContent/offer";
import { arrowSettings } from "constants/offer.constants";

const cards = products.map(({ name, shortNote }, index) => (
  <div key={index} className={`offer-card offer-card--${index + 1}`}>
    <h2 className="offer-card__title">{name}</h2>
    <p className="offer-card__text">{shortNote}</p>
    <div className="offer-card__arrow-wrapper">
      <span className="offer-card__arrow">
        <Arrow {...arrowSettings} />
        <Arrow {...arrowSettings} />
        <Arrow {...arrowSettings} />
        <a href={`#articleOffer${index}`}>więcej</a>
      </span>
    </div>
  </div>
));

const OfferCards = () => <div className="offer__cards">{cards}</div>;

export default OfferCards;
