import React, { useRef } from "react";
import { showParagraph } from "staticContent/offer";

const OfferArticle = ({ paragraphNum, name, description, pageWidth }) => {
  const paragraphRefs = {
    0: useRef(),
    1: useRef(),
    2: useRef(),
  };

  const handleClick = (e, index) => {
    e.target.style.display = "none";
    paragraphRefs[index].current.style.display = "block";
  };

  return (
    <article
      className={`offer-product__article offer-product__article--${
        paragraphNum === 1 ? "right" : "left"
      }`}
    >
      <div className="offer-product__title" id={`articleOffer${paragraphNum}`}>
        <h2
          className={`offer-product__title-name offer-product__title-name--${
            paragraphNum === 1 ? "right" : "left"
          }`}
        >
          {name}
        </h2>
      </div>
      <p className="offer-product__paragraph" ref={paragraphRefs[paragraphNum]}>
        {description}
      </p>
      {pageWidth < 600 && (
        <div
          className="after-paragraph"
          role="button"
          tabIndex="0"
          onClick={e => handleClick(e, paragraphNum)}
          onKeyDown={e => handleClick(e, paragraphNum)}
        >
          {showParagraph}
        </div>
      )}
    </article>
  );
};

export default OfferArticle;
