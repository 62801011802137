import React from "react";
import OfferBtn from "./OfferBtn";
import { commercialOffer } from "staticContent/offer";
import { logoImg } from "constants/offer.constants";
import { offerDocs } from "routes/docsPaths";

const OfferCommercial = () => (
  <div className="offer__commercial-wrapper" id="commercialOffer">
    <h2 className="offer__subtitle">{commercialOffer.title}</h2>
    {logoImg}
    <div className="offer__commercial--btns-wrapper">
      <OfferBtn path={offerDocs.hvEngOffer} text={commercialOffer.btnEng} />
      <OfferBtn path={offerDocs.hvPlOffer} text={commercialOffer.btnPl} />
    </div>
  </div>
);

export default OfferCommercial;
