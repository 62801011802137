import React, { useEffect, useRef } from "react";
import { useLocation } from "@reach/router";
import { heroContent } from "staticContent/offer";
import OfferCards from "./OfferCards";
import OfferProducts from "./OfferProducts";
import OfferTechStack from "./OfferTechStack";
import OfferCommercial from "./OfferCommercial";
import { CompasBreakLine } from "utils";
import { diagramImg } from "constants/offer.constants";
import "styles/components/offer.scss";

const Offer = location => {
  const { hash } = useLocation();
  const commercial = useRef();

  useEffect(() => {
    setTimeout(() => {
      hash === "#commercialOffer" &&
        commercial.current.scrollIntoView({ behavior: "smooth" });
    }, 0);
  }, [hash]);

  return (
    <>
      <section className="section hero">
        <h1 className="hero__banner-text">{heroContent.sectionTitle}</h1>
      </section>
      <section className="section cards">
        <OfferCards />
      </section>
      <section className="section">
        {diagramImg}
        <OfferProducts />
      </section>
      <section className="section" ref={commercial}>
        <CompasBreakLine />
        <OfferCommercial isComercial={location} />
      </section>
      <section className="section offer__tech-stack">
        <CompasBreakLine />
        <OfferTechStack />
      </section>
    </>
  );
};

export default Offer;
