import React from "react";
import Layout from "utils/Layout";
import Offer from "components/Offer";
import { heroContent } from "staticContent/offer";

const IndexPage = () => (
  <Layout subTitle={heroContent.sectionTitle}>
    <Offer />
  </Layout>
);

export default IndexPage;
