import React from "react";
import { Button } from "utils";

const OfferBtn = ({ path, text }) => (
  <a href={path} rel="noopener noreferrer" target="_blank">
    <Button children={text} className="offer__commercial--btn" />
  </a>
);

export default OfferBtn;
