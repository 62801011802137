import React from "react";
import { techStackContent } from "staticContent/offer";

const techIcons = techStackContent.icons.map(({ name, pic, path }, index) => (
  <a key={index} href={path} target="_blank" rel="noreferrer noopener">
    <img
      className="tech-stack__img"
      loading="lazy"
      src={pic}
      alt={`logo ${name}`}
    />
  </a>
));

const OfferTechStack = () => (
  <>
    <h2 className="offer__subtitle">{techStackContent.title}</h2>
    <div className="tech-stack__wrapper">{techIcons}</div>
  </>
);

export default OfferTechStack;
