import React, { useState, useEffect } from "react";
import OfferArticle from "./OfferArticle";
import { products } from "staticContent/offer";
import { productPics } from "constants/offer.constants";

const OfferProducts = () => {
  const [pageWidth, setPageWidth] = useState(0);

  useEffect(() => {
    setPageWidth(window.screen.width);
  }, []);

  const articles = products.map(({ name, description }, index) => (
    <React.Fragment key={index}>
      {index === 1 && productPics[index]}
      <OfferArticle
        paragraphNum={index}
        name={name}
        description={description}
        pageWidth={pageWidth}
      />
      {index === 0 && productPics[index]}
      {index === 2 && productPics[index]}
    </React.Fragment>
  ));

  return <div className="offer__products-wrapper">{articles}</div>;
};

export default OfferProducts;
